import React from "react";
import styled from "styled-components";
import AnimSide from "../Animation/AnimSide";
import AnimeImage from "../Animation/AnimeImage";

const ApplicationData = {
  title: "<span>Prayer Education</span> in Your Pocket",
  subhead:
    "The app features step-by-step guides for 14 prayers with images that show correct postures, and Quran recitations in English & Arabic. It also tells you when it’s time to pray, and shows you the direction of the Qibla.<br /> <br /> Upcoming updates include 11+ prayers, other languages, and even more useful features!"
};

const MobileApplication = () => {
  return (
    <SectionWrapper>
      <ContainerSection>
        <AnimSide reversed>
          <ContentSection>
            <SectionHeader
              dangerouslySetInnerHTML={{ __html: ApplicationData.title }}
            />
            <SectionSubhead
              dangerouslySetInnerHTML={{ __html: ApplicationData.subhead }}
            />
            <PointsWrapper>
              <Point>
                <img src="check-mark.png" alt="check-mark" />
                <span>Ad-free experience</span>
              </Point>
              <Point>
                <img src="check-mark.png" alt="check-mark" />
                <span>Privacy &amp; data protection</span>
              </Point>
            </PointsWrapper>
            <DownloadIconsWrapper>
              <a
                href="https://play.google.com/store/apps/details?id=com.getsajdah.sajdah.android"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/google-play.svg" alt="Get Sajdah from Google Play Store" />
              </a>
              <a
                href="https://apps.apple.com/us/app/sajdah-prayer-guide-times/id1557291793"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/app-store.svg" alt="Get Sajdah from Apple App Store" />
              </a>
              <Arrow src="/arrow-deco.svg" />
            </DownloadIconsWrapper>
            <Cta>The app is ready right now! Try it out</Cta>
          </ContentSection>
        </AnimSide>
        
        <AnimeImage className="anim">
          <ImageSection>
            <img src="/iphone-mockup.svg" alt="Iphone sajdah" />
          </ImageSection>
        </AnimeImage>
      </ContainerSection>
    </SectionWrapper>
  );
};

MobileApplication.propTypes = {};

MobileApplication.defaultProps = {};

const SectionWrapper = styled.div`
  width: 100%;
  padding: 72px 0 0;
  text-align: center;
  background-image: linear-gradient(to left, #eaf9f6 0%, #ffffff 100%);
`;

const ContentSection = styled.div`
  width: 100%;
  max-width: 600px;
  text-align: left;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const ContainerSection = styled.div`
  width: 100%;
  max-width: 1152px;
  display: flex;
  justify-content: space-between;
  align-items:flex-end;
  margin: 0 auto;
  @media (max-width: 1280px) {
    max-width: 90%;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .anim {
    @media (max-width: 768px) {
      max-width: 90%;
      height: auto;
      margin: 0px 32px;
      position: relative;
      bottom: 0px;
    }
  }
`;

const SectionHeader = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 45px;
  line-height: 55px;
  color: #2d2d2d;
  font-weight: 500;
  overflow: hidden;
  position: relative;
  padding-bottom: 38px;
  max-width: 400px;
  @media (max-width: 768px) {
    font-size: 35px;
    max-width: 80%;
    margin: 0 auto;
  }
  span {
    color: #008e8e;
    font-weight: 700;
    text-shadow: none;
  }
`;
const SectionSubhead = styled.h5`
  color: #292929;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 400;
  margin: 33px auto 0;
  max-width: 643px;
  @media (max-width: 768px) {
    max-width: 80%;
    margin: 30px auto 0;
  }
`;

const DownloadIconsWrapper = styled.div`
  width: 100%;
  max-width: 346px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
  position: relative;
  @media (max-width: 768px) {
    margin: 32px auto 0;
  }
  a {
    height: auto;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 164px;
    img {
      width: 100%;
      max-width: 164px;
    }
  }
`;

const Arrow = styled.img`
  width: 65px;
  position: absolute;
  right: 0px;
  transform: rotate(90deg);
  top: 25px;
  margin-right: -100px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const ImageSection = styled.div`
  width: 100%;
  max-width: 414px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  @media (max-width: 1280px) {
    max-width: 320px;
  }
  @media (max-width: 768px) {
    max-width: 100%;
    margin: 0 auto;
    img {
      max-width: 600px;
      height: auto;
      margin: 0 32px;
      position: relative;
      bottom: 0;
    }
  }
`;

const Cta = styled.span`
  display: block;
  margin: 30px 0 101px;
  color: #008e8e;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  @media (max-width: 768px) {
    text-align: center;
    margin: 30px 24px 40px;
  }
`;

const PointsWrapper = styled.div`
  width: 100%;
  max-width: 443px;
  background-color: #eaf9f6;
  padding: 27px 28px 9px;
  margin-top: 33px;
  @media (max-width: 768px) {
    max-width: 80%;
    margin: 30px auto 0;
  }
`;

const Point = styled.div`
  color: #008e8e;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  img {
    margin-right: 11px;
  }
`;

export default MobileApplication;
