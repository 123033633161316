import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import styled from "styled-components";

import Image from "gatsby-image";
import AnimSide from "../Animation/AnimSide";
const ReasonsData = {
  title: "Who Should Use <span>Sajdah?</span>",
  subhead: "Sajdah is the perfect fit for every Muslim"
};

const SajdahReasons = () => {
  const ReasonsImages = useStaticQuery(graphql`
    query ReasonsImages {
      kids: file(absolutePath: { regex: "/images/kids.png/" }) {
        childImageSharp {
          fluid(maxWidth: 595) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      konwledgable: file(
        absolutePath: { regex: "/images/Knowledgable_Muslim2.png/" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1160) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      muslims: file(absolutePath: { regex: "/images/New_Muslims.png/" }) {
        childImageSharp {
          fluid(maxWidth: 1160) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <SectionWrapper>
      <SectionHeader dangerouslySetInnerHTML={{ __html: ReasonsData.title }} />
      <SectionSubhead>{ReasonsData.subhead}</SectionSubhead>
      <ReasonsContainer>
        <SideCard>
          <ImageSide>
            <Image
              fluid={ReasonsImages.muslims.childImageSharp.fluid}
              alt="konwledgable muslims"
            />
          </ImageSide>
          <ContentSide>
            <AnimSide delay="0">
              <ContentWrapper>
                <IconWrapper>
                  <img src="/muslim.png" alt="konwledgable muslims" />
                </IconWrapper>
                <TitleCard
                  dangerouslySetInnerHTML={{
                    __html:
                      "Fulfilling Learning Experience for <span>New Muslims!</span>"
                  }}
                />

                <TextCard>
                  Sajdah acts as your personal trainer, guiding you through the 5
                  daily mandatory prayers, as well as 20 others. Learn with Sajdah
                  without worrying whether you’re forgetting something or if
                  you’re reciting the Qur’an properly. Use the app to learn
                  postures and the order of every prayer.
                </TextCard>
              </ContentWrapper>
            </AnimSide>
          </ContentSide>
        </SideCard>
        <SideCard>
          <ImageSide>
            <Image
              fluid={ReasonsImages.kids.childImageSharp.fluid}
              alt="kids muslims"
            />
          </ImageSide>
          <ContentSide>
            <AnimSide reversed>
              <ContentWrapper>
                <IconWrapper>
                  <img src="/muslim_2.png" alt="kids muslims" />
                </IconWrapper>
                <TitleCard
                  dangerouslySetInnerHTML={{
                    __html:
                      "Simple Prayer Learning Experience for <span>Children!</span>"
                  }}
                />

                <TextCard>
                  Sajdah helps your children learn prayer through simple,
                  easy-to-follow steps for every prayer. They’ll feel like they
                  have a personal trainer guiding them, and they’ll be drawn to
                  Sajdah because of its tech-based features. Sajdah is also useful
                  for children who get distracted easily or have disordered such
                  as ADHD.
                </TextCard>
              </ContentWrapper>
            </AnimSide>
          </ContentSide>
        </SideCard>
        <SideCard>
          <ImageSide>
            <Image
              fluid={ReasonsImages.konwledgable.childImageSharp.fluid}
              alt="new muslims"
            />
          </ImageSide>
          <ContentSide>
            <AnimSide>
              <ContentWrapper>
                <IconWrapper>
                  <img src="/dua-hands.png" alt="new muslims" />
                </IconWrapper>
                <TitleCard
                  dangerouslySetInnerHTML={{
                    __html:
                      "Prayer Companion for <span>Knowledgeable Muslims!</span>"
                  }}
                />

                <TextCard>
                  If you already know how to pray, Sajdah helps you concentrate
                  during Taraweeh and other prayers while reading the Qur’an off
                  its LED screen. You can also use it to listen to the Qur’an
                  wherever you are through its built-in speakers. Sajdah is a
                  great praying tool for Muslims who can’t memorize longer
                  chapters of the Qur’an as well.
                </TextCard>
              </ContentWrapper>
            </AnimSide>
          </ContentSide>
        </SideCard>
      </ReasonsContainer>
    </SectionWrapper>
  );
};

SajdahReasons.propTypes = {};

SajdahReasons.defaultProps = {};

const SectionWrapper = styled.div`
  width: 100%;
  margin-top: 67px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ReasonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 67px;
`;

const SectionHeader = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 45px;
  line-height: 55px;
  color: #2d2d2d;
  font-weight: 500;
  margin: 0 auto;
  max-width: 600px;
  text-align: center;
  overflow: hidden;
  position: relative;
  padding-bottom: 28px;
  span {
    color: #008e8e;
    font-weight: 700;
    text-shadow: none;
  }
  ::after {
    width: 100%;
    max-width: 410px;
    margin: 0 auto;
    height: 10px;
    transform: translate(-50%, 0);
    background-image: url("/divider.svg");
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-position: center center;
    bottom: 0;
    left: 50%;
  }
  @media (max-width: 768px) {
    font-size: 35px;
    max-width: 432px;
    margin: 0 24px;
  }
`;
const SectionSubhead = styled.h5`
  color: #292929;
  font-family: Poppins, sans-serif;
  font-size: 17px;
  font-weight: 500;
  margin: 33px 0 0;
  @media (max-width: 768px) {
    margin: 33px 24px 0;
  }
`;
const SideCard = styled.div`
  width: 100%;
  display: flex;
  :nth-of-type(2n) {
    flex-direction: row-reverse;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    :nth-of-type(2n) {
      flex-direction: column;
    }
  }
`;

const ImageSide = styled.div`
  width: 50%;

  div {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ContentSide = styled.div`
  overflow:hidden;
  width: 50%;
  background-image: linear-gradient(to left, #eaf9f6 0%, #ffffff 100%);
  :nth-of-type(2n) {
    background-image: linear-gradient(to right, #eaf9f6 0%, #ffffff 100%);
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  padding: 73px 81px 77px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 768px) {
    padding: 48px 24px;
  }
`;

const IconWrapper = styled.div`
  width: 96px;
  height: 96px;
  border-radius: 100%;
  background-color: white;
  transform: translate(-18px, 0);
  box-shadow: 3px 4px 65px rgba(0, 0, 0, 0.09);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 33px;
  ::after {
    width: 80px;
    box-shadow: 3px 4px 65px rgba(0, 0, 0, 0.09);
    height: 80px;
    transform: translate(-50%, -50%);
    background-image: white;
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 100%;
  }
  ::before {
    width: 62px;
    box-shadow: 3px 4px 65px rgba(0, 0, 0, 0.09);
    height: 62px;
    transform: translate(-50%, -50%);
    background-image: white;
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 100%;
  }
  @media (max-width: 768px) {
    transform: translate(0, 0);
  }
  @media (max-width: 470px) {
    margin: 0 auto 33px;
  }
`;
const TitleCard = styled.div`
  font-family: Poppins, sans-serif;
  font-weight: 600;
  margin: 0 0 14px;
  color: #000000;
  font-size: 30px;
  line-height: 44px;
  text-align: left;
  span {
    color: #008e8e;
    font-weight: 700;
  }
`;

const TextCard = styled.p`
  color: #333333;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 33px;
  height: 184px;
  margin: 0;
  text-align: left;
  font-size: 18px;
  @media (max-width: 1280px) {
    height: auto;
  }
`;
export default SajdahReasons;
