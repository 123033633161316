import React, { Component } from "react";
import styled from "styled-components";
import ReactPlayer from "react-player/lazy";
import AnimeUp from "../Animation/AnimeUp"
const ExplanationData = {
  title: "Most Innovative Product In <span>Islamic Education</span>",
  subhead: "See how Sajdah works in this quick video.",
  videoSrc: "https://youtu.be/2gtfDAjIiDs"
};

export default class VideoExplanation extends Component {
  state = {
    playing: false
  };

  render() {
    return (
      <SectionWrapper>
        <SectionHeader
          dangerouslySetInnerHTML={{ __html: ExplanationData.title }}
        />
        <SectionSubhead>{ExplanationData.subhead}</SectionSubhead>
        <Container>
          <AnimeUp>
            <WrapperIframe>
                <ContainerIframe>
                  <ReactPlayer
                    className="responsive-iframe"
                    url={ExplanationData.videoSrc}
                    playing={this.state.playing}
                    muted={this.state.playing}
                    loop
                  />
                </ContainerIframe>
            </WrapperIframe>
          </AnimeUp>
        </Container>
       
       
      </SectionWrapper>
    );
  }
}

VideoExplanation.propTypes = {};

VideoExplanation.defaultProps = {};

const SectionWrapper = styled.div`
  margin-top: 50px;
  text-align: center;
  width: 100%;
  position: relative;
  overflow:hidden;
`;

const SectionSubhead = styled.h5`
  color: #292929;
  font-family: Poppins, sans-serif;
  font-size: 17px;
  font-weight: 500;
`;

const WrapperIframe = styled.div`
  max-width: 1200px;
  margin: 25px auto;
  border: 4px solid white;
  border-radius: 4px;
  box-shadow: -1px 4px 17px -4px #000000;
  @media (max-width: 1440px) {
    max-width: 960px;
  }
  @media (max-width: 1280px) {
    max-width:90%;
  }
  @media (max-width: 768px) {
    max-width: 90%;
  }
  @media (max-width: 470px) {
    max-width: 450px;
    margin: 25px 16px;
  }
`;

const Container = styled.div`
  width:100%;
  max-width:100%;
`

const ContainerIframe = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100% !important;
    height: 100% !important;
  }
`;

const SectionHeader = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 45px;
  line-height: 55px;
  color: #2d2d2d;
  font-weight: 500;
  margin: 0 auto;
  max-width: 600px;
  text-align: center;
  overflow: hidden;
  position: relative;
  padding-bottom: 28px;
  span {
    color: #008e8e;
    font-weight: 700;
    text-shadow: none;
  }
  ::after {
    width: 100%;
    max-width: 410px;
    margin: 0 auto;
    height: 10px;
    transform: translate(-50%, 0);
    background-image: url("/divider.svg");
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-position: center center;
    bottom: 0;
    left: 50%;
  }
  @media (max-width: 768px) {
    font-size: 35px;
    max-width: 440px;
  }
  @media (max-width: 470px) {
    font-size: 30px;
    max-width: 400px;
    max-width:90%;
  }
`;

