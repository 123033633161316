import React from 'react';
import styled from 'styled-components';

const preorder = false;

const HeroData = {
  title: 'World’s First <span>Smart Educational Prayer Rug</span> For Every Muslim',
  imageGadgets: '/gadgets.png',
  sajdahImage: '/sajdah-img.png',
  icon: '/launch-good-black.svg',
  titleCta: 'Available Exclusively On',
  textCta:
    'Be one of the first to get access to <span>Sajdah</span> at an exclusive <span>51% off!</span>',
  text:
    "Sajdah teaches new Muslims how to pray properly without any worries. Muslims can also improve their prayer experience by reading the Qur'an from Sajdah's LED screen during Taraweeh, Qyam, and other prayers."
};

const TechData = {
  title: 'Technical <span>Specification</span>',
  subhead:
    'Limited quantities will be available on the first production run. Secure your Sajdah today!',
  iconsList: [
    {
      title: 'Battery',
      image: '/tech_icons/battery-status.png',
      textExplainer: 'Built-in rechargeable lithium-ion battery(included)'
    },
    {
      title: 'Backup',
      image: '/tech_icons/charging.png',
      textExplainer: '6 hours'
    },
    {
      title: 'Charging',
      image: '/tech_icons/phone-charger.png',
      textExplainer: 'USB Type-C'
    },
    {
      title: 'Dimensions',
      image: '/tech_icons/dimensions.png',
      textExplainer: '180cm x 70cm *Actual size may vary '
    },
    {
      title: 'Bluetooth',
      image: '/tech_icons/bluetooth.png',
      textExplainer: 'Supports Bluetooth connectivity with smartphones'
    },
    {
      title: 'WiFi',
      image: '/tech_icons/wifi-logo.png',
      textExplainer: 'Support for OTA (Over the Air) updates'
    },
    {
      title: 'Screen Stand',
      image: '/tech_icons/screen.png',
      textExplainer: '45°'
    },
    {
      title: 'LED Screen Area',
      image: '/tech_icons/prayer.png',
      textExplainer: '512mm x 256mm (32,768 RGB LEDs)'
    },
    {
      title: 'Audio',
      image: '/tech_icons/speaker-interface-audio-symbol.png',
      textExplainer: 'Built-in speaker'
    },
    {
      title: 'Requirements',
      image: '/tech_icons/check-list_(1).png',
      textExplainer: 'Sajdah comes ready to connect to your iOS and Android devices.'
    }
  ]
};

const TechSpecification = () => {
  return (
    <SectionWrapper>
      <SectionHeader dangerouslySetInnerHTML={{ __html: TechData.title }} />
      <SectionContainer>
        <IconsWrapper>
          {TechData.iconsList.map((item, index) => {
            return (
              <IconItem key={index}>
                <IconImage>
                  <img src={item.image} alt={item.title} />
                </IconImage>
                <TitleItem>{item.title}</TitleItem>
                <Explainer>
                  <p>{item.textExplainer}</p>
                </Explainer>
              </IconItem>
            );
          })}
        </IconsWrapper>
        <Subhead>{TechData.subhead}</Subhead>
        {preorder && (
          <SubscribeWrapper>
            <BannerHeader>
              <h5>{HeroData.titleCta}</h5>
              <img src={HeroData.icon} alt={HeroData.titleCta} />
            </BannerHeader>
            <a href="https://launchgood.com/sajdah?utm_source=sajdah&utm_medium=website&utm_campaign=pre_order">
              <ButtonCta>Get Sajdah Now</ButtonCta>
            </a>
          </SubscribeWrapper>
        )}

        {!preorder && (
          <SubscribeWrapper>
            <FormInfo>
              <TitleForm>Sign-up to get the latest news and offers</TitleForm>
            </FormInfo>
            <FormWrapper>
              <Form
                id="subscribe-form-homepage-middle"
                method="post"
                action="https://getsajdah.us5.list-manage.com/subscribe/post"
              >
                <input type="hidden" name="u" value="b1c1bdd42683574d15089cb6d" />
                <input type="hidden" name="id" value="a590d16f80" />
                <StyledInput placeholder="Enter your email" name="EMAIL" type="email" required />
                <StyledButton type="submit" name="subscribe">
                  Sign-up
                </StyledButton>
              </Form>
            </FormWrapper>
          </SubscribeWrapper>
        )}
      </SectionContainer>
    </SectionWrapper>
  );
};

TechSpecification.propTypes = {};

TechSpecification.defaultProps = {};

const BannerHeader = styled.div`
  display: flex;
  h5 {
    color: #292929;
    font-family: Poppins, sans-serif;
    font-size: 22px;
    font-weight: 500;
    margin-right: 14px;
  }
  @media (max-width: 470px) {
    flex-direction: column;
    align-items: center;
    h5 {
      margin: 8px 0;
    }
    img {
      margin-bottom: 25px;
    }
  }
`;

const ButtonCta = styled.button`
  padding: 25px 45px;
  color: #ffffff;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  text-transform: uppercase;
  background-color: rgb(242, 74, 70);
  outline: none;
  border: none;
  cursor: pointer;
`;

const SectionWrapper = styled.div`
  padding: 75px 0 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(to left, #eaf9f6 0%, #ffffff 100%);
  position: relative;
  overflow: hidden;
  width: 100%;
  ::before {
    width: 100%;
    max-width: 1126px;
    margin: 0 auto;
    height: 100%;
    background-image: url('/bg-shariah.png');
    transform: rotate(-180deg);
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    background-position: center center;
    bottom: 0;
    left: 0;
    z-index: 1;
    opacity: 0.5;
  }
  ::after {
    width: 100%;
    max-width: 1126px;
    margin: 0 auto;
    height: 100%;
    background-image: url('/bg-shariah.png');
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    background-position: center center;
    top: 0;
    right: 0;
    z-index: 1;
    opacity: 0.5;
  }
`;

const SectionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    max-width: 90%;
  }
`;

const SectionHeader = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 45px;
  line-height: 55px;
  color: #2d2d2d;
  font-weight: 500;
  margin: 0 auto;
  max-width: 680px;
  text-align: center;
  overflow: hidden;
  position: relative;
  padding-bottom: 28px;
  z-index: 100;
  @media (max-width: 768px) {
    font-size: 35px;
    max-width: 283px;
  }
  span {
    color: #008e8e;
    font-weight: 700;
    text-shadow: none;
  }
  ::after {
    width: 100%;
    margin: 0 auto;
    height: 10px;
    transform: translate(-50%, 0);
    background-image: url('/divider.svg');
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    background-position: center center;
    bottom: 0;
    left: 50%;
  }
`;

const IconsWrapper = styled.div`
  width: 100%;
  max-width: 1080px;
  display: flex;
  justify-content: center;
  margin: 30px -12px 45px;
  flex-wrap: wrap;
  position: relative;
  z-index: 100;
  @media (max-width: 768px) {
    max-width: 600px;
    margin: 30px auto 45px;
  }
  @media (max-width: 470px) {
    max-width: 338px;
    margin: 30px auto 45px;
  }
`;

const Explainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 100%;
  background-color: #008e8e;
  border-radius: 4px;
  opacity: 0.1;
  transition: all 0.4s ease-in-out;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    color: white;
    font-size: 14px;
    font-family: Poppins, sans-serif;
    margin: 0;
    max-width: 90%;
  }
`;

const IconItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 17%;
  margin: 0 12px 10px;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 20px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  @media (max-width: 470px) {
    margin: 0 4px 10px;
    padding: 23px 30px;
    max-width: 25%;
  }
  &:hover ${Explainer} {
    opacity: 1;
    top: 0;
  }
`;

const IconImage = styled.div`
  width: 59px;
  height: 59px;
  border-radius: 100%;
  background: #008e8e;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleItem = styled.span`
  color: #292929;
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 30px;
  @media (max-width: 768px) {
    line-height: 1.2;
    margin-top: 8px;
  }
`;

const Subhead = styled.div`
  color: #292929;
  font-family: Poppins, sans-serif;
  font-size: 17px;
  font-weight: 500;
  max-width: 768px;
  margin: 0;
  position: relative;
  z-index: 100;
  @media (max-width: 768px) {
    max-width: 555px;
  }
  @media (max-width: 470px) {
    max-width: 80%;
  }
`;

const SubscribeWrapper = styled.div`
  width: 100%;
  max-width: 1105px;
  margin: 38px auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 36px;
  background-color: #ffffff;
  box-shadow: 3px 4px 70px rgba(0, 0, 0, 0.06);
  position: relative;
  z-index: 10;
  @media (max-width: 1280px) {
    max-width: 90%;
  }
  @media (max-width: 768px) {
    max-width: 555px;
    flex-direction: column;
  }
  @media (max-width: 470px) {
    max-width: 80%;
    padding: 24px;
  }
`;

const FormInfo = styled.div`
  width: 100%;
  max-width: 200px;
`;

const FormWrapper = styled.div`
  width: 100%;
  max-width: 780px;
`;

const TitleForm = styled.h3`
  color: #292929;
  font-family: Poppins, sans-serif;
  font-size: 17px;
  font-weight: 500;
`;

const Form = styled.form`
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  @media (max-width: 470px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledInput = styled.input`
  height: 15px;
  width: 100%;
  max-width: 530px;
  outline: none;
  color: #292929;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  line-height: 15px;
  font-weight: 300;
  background: transparent;
  padding: 23px 25px;
  border: 1px solid #e5e5e5;
  ::-webkit-input-placeholder {
    opacity: 0.6;
    color: #292929;
  }
  @media (max-width: 768px) {
    padding: 18px 22px;
  }
  @media (max-width: 470px) {
    max-width: 80%;
    margin: 0 auto 19px;
  }
`;

const StyledButton = styled.button`
  background-color: #008e8e;
  color: white;
  border-radius: 4px;
  outline: none;
  border: none;
  padding: 8px 12px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  cursor: pointer;
  width: 100%;
  max-width: 240px;
  margin-left: 8px;
  :hover {
    opacity: 0.9;
  }
  @media (max-width: 470px) {
    padding: 24px 12px;
  }
`;

export default TechSpecification;
