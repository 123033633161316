import React from "react";
import styled from "styled-components";

const HowItWorksData = {
  title: "How Does <span>Sajdah</span> Work?",
  subhead:
    "Sajdah guides you through more than 25 prayers with its discrete LED screen and built-in speakers with Quran recitations in English & Arabic. You can learn prayers as a new Muslim or use Sajdah to recite and memorize the Quran."
};

const HowSajdahWorks = () => {
  return (
    <SectionWrapper>
      <SectionHeader
        dangerouslySetInnerHTML={{ __html: HowItWorksData.title }}
      />
      <SectionSubhead>{HowItWorksData.subhead}</SectionSubhead>
      <ExplanationSteps>
        <ItemStep>
          <IconItem>
            <img
              src="/connect-with-app.png"
              alt="connect-with-app.png"
              className="first-image"
            />
          </IconItem>
          <TextItem>
            <TitleTextItem>Connect with Mobile App</TitleTextItem>
            {/* <TextTestItem>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </TextTestItem> */}
          </TextItem>
        </ItemStep>
        <ItemStep>
          <IconItem className="icon-item">
            <img src="/select-prayer.png" alt="connect-with-app.png" />
          </IconItem>
          <TextItem>
            <TitleTextItem>Select the Prayer</TitleTextItem>
            {/* <TextTestItem>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </TextTestItem> */}
          </TextItem>
        </ItemStep>
        <ItemStep>
          <IconItem className="icon-item">
            <img src="/follow-instructions.png" alt="connect-with-app.png" />
          </IconItem>
          <TextItem className="third-text">
            <TitleTextItem>Follow on-screen instructions</TitleTextItem>
            {/* <TextTestItem>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </TextTestItem> */}
          </TextItem>
        </ItemStep>
        <MainLine>
          <PointLine />
          <PointLine />
          <PointLine />
          <PointLine />
        </MainLine>
      </ExplanationSteps>
    </SectionWrapper>
  );
};

HowSajdahWorks.propTypes = {};

HowSajdahWorks.defaultProps = {};

const SectionWrapper = styled.div`
  width: 100%;
  padding-top: 72px;
  padding-bottom: 161px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(to left, #eaf9f6 0%, #ffffff 100%);
  position: relative;
  overflow: hidden;
  ::after {
    width: 100%;
    max-width: 762px;
    margin: 0 auto;
    height: 576px;
    background-image: url("/work-bg.png");
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-position: center center;
    bottom: 0;
    right: 0;
  }
  @media (max-width: 768px) {
    padding-bottom: 32px;
  }
`;
const SectionHeader = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 45px;
  line-height: 55px;
  color: #2d2d2d;
  font-weight: 500;
  margin: 0 auto;
  max-width: 600px;
  text-align: center;
  overflow: hidden;
  position: relative;
  padding-bottom: 28px;
  span {
    color: #008e8e;
    font-weight: 700;
    text-shadow: none;
  }
  ::after {
    width: 100%;
    max-width: 410px;
    margin: 0 auto;
    height: 10px;
    transform: translate(-50%, 0);
    background-image: url("/divider.svg");
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-position: center center;
    bottom: 0;
    left: 50%;
  }
  @media (max-width: 768px) {
    margin: 0 24px;
    font-size: 35px;
  }
`;
const SectionSubhead = styled.h5`
  color: #292929;
  font-family: Poppins, sans-serif;
  font-size: 17px;
  font-weight: 500;
  margin: 33px 0 0;
  max-width: 807px;
  @media (max-width: 768px) {
    margin: 33px 24px 0;
  }
`;
const ExplanationSteps = styled.div`
  margin: 70px auto 161px;
  width: 100%;
  max-width: 1169px;
  position: relative;
  height: 220px;
  @media (max-width: 1280px) {
    max-width:90%;
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 1000px;
    justify-content: space-between;
  }
`;

const MainLine = styled.div`
  width: 100%;
  height: 2px;
  background-color: black;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  @media (max-width: 768px) {
    display: none;
  }
`;

const PointLine = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: black;
  top: 50%;
  transform: translate(0, -50%);
  position: absolute;
  :nth-of-type(2) {
    left: 33%;
  }
  :nth-of-type(3) {
    left: 66%;
  }
  :nth-of-type(4) {
    left: 100%;
  }
`;

const ItemStep = styled.div`
  width: 218px;
  height: 218px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  position: absolute;
  left: 9%;
  top: 50%;
  transform: translate(0, -50%);
  
  @media (max-width: 768px) {
    position: relative;
    transform: none;
    left: auto;
    top: auto;
    height: auto;
  }
  .third-text {
    margin-top: 109px;
    @media (max-width: 768px) {
      margin-top: 0;
    }
  }
  z-index: 10;
  :nth-of-type(1) {
    border-radius: 100%;
  }
  :nth-of-type(2) {
    left: 40%;
    width: 218px;
    height: 109px;
    border-bottom-left-radius: 109px;
    border-bottom-right-radius: 109px;
    border-top: 0;
    top: 163.5px;
    box-sizing: border-box;
    @media (max-width: 768px) {
      left: auto;
      top: auto;
      margin-top: 0%;
      border-radius: 100%;
      height: 218px;
    }
    .icon-item {
      margin-bottom: 50%;
      ::after {
        margin-top: -25%;
      }
      ::before {
        margin-top: -25%;
      }
      @media (max-width: 768px) {
        margin-bottom: 0%;
        ::after {
          margin-top: 0%;
        }
        ::before {
          margin-top: 0%;
        }
      }
    }
  }
  :nth-of-type(3) {
    left: 74%;
    width: 218px;
    height: 109px;
    border-top-left-radius: 109px;
    border-top-right-radius: 109px;
    border-bottom: 0;
    top: 55.5px;
    box-sizing: border-box;
    @media (max-width: 768px) {
      left: auto;
      top: auto;
      margin-top: 0%;
      border-radius: 100%;
      height: 218px;
    }
    .icon-item {
      margin-top: 50%;
      ::after {
        margin-top: 25%;
      }
      ::before {
        margin-top: 25%;
      }
      @media (max-width: 768px) {
        margin-top: 0%;
        ::after {
          margin-top: 0%;
        }
        ::before {
          margin-top: 0%;
        }
      }
    }
  }
`;

const IconItem = styled.div`
  width: 197px;
  height: 197px;
  border-radius: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  .first-image {
    margin-left: -20px;
  }
  ::after {
    width: 150px;
    box-shadow: 3px 4px 65px rgba(0, 0, 0, 0.09);
    height: 150px;
    transform: translate(-50%, -50%);
    background-image: white;
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 100%;
  }
  ::before {
    width: 175px;
    box-shadow: 3px 4px 65px rgba(0, 0, 0, 0.09);
    height: 175px;
    transform: translate(-50%, -50%);
    background-image: white;
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 100%;
  }
`;

const TextItem = styled.div`
  width: 280px;
  height: 200px;
  position: absolute;
  top: 100%;
  padding-top: 33px;
  text-align: center;
  @media (max-width: 768px) {
    top: 209px;
    margin-top: 24px;
    padding-top: 0;
    width: auto;
  }
`;

const TitleTextItem = styled.h5`
  color: #333333;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 20px;
  @media (max-width: 768px) {
    margin: 24px 0 0;
  }
`;

// const TextTestItem = styled.p`
//   font-family: Poppins, sans-serif;
//   font-size: 14px;
//   font-weight: 400;
//   font-style: normal;
//   letter-spacing: normal;
//   line-height: 23px;
//   max-width: 212px;
//   margin: 0 auto;
// `;

export default HowSajdahWorks;
