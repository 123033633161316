import React from 'react';
import styled from 'styled-components';

const MeetTeamData = {
  title: 'Meet <span>Our Team</span>',
  teamMembers: [
    {
      name: 'Abdulrahman Saleh Khamis',
      image: '/team_photos/abdulrahman.jpg',
      position: 'CEO and Co-Founder'
    },
    {
      name: 'Abdul Ali',
      image: '/team_photos/abdul.jpg',
      position: 'Chief Growth Officer and Co-Founder'
    },
    {
      name: 'Eduardo Aguirre Quintana',
      image: '/team_photos/eduardo.jpg',
      position: 'Product Engineer'
    },
    {
      name: 'Gilroy Philbert Pereira',
      image: '/team_photos/gilroy.jpg',
      position: 'Embedded Software Engineer'
    },
    {
      name: 'Sheikh Bilal Dannoun',
      image: '/team_photos/bilal.jpg',
      position: 'Shariah Advisor',
      info:
        'Founder of daleel.global, Advisor and Project Coordinator of “My Prayer” illustrated prayer mat'
    },
    {
      name: 'Prof. Amine Bermak',
      image: '/team_photos/prof_amine.jpg',
      position: 'Technical Advisor',
      info:
        'Professor and Associate Dean at HBKU, IEEE Fellow, World-Leading Author, Inventor of Time-Domain Image Sensing'
    },
    {
      name: 'Nicolas Avril',
      image: '/team_photos/nicolas.jpg',
      position: 'Manufacturing Advisor',
      info: 'Ex-VP of Dragon Innovation, MIT, Founder of Enging.net'
    },
    // {
    //   name: 'Fabien Gaussorgues',
    //   image: '/team_photos/fabien.jpg',
    //   position: 'Manufacturing Partner',
    //   info: 'CEO of Agilian, COO of Sofeast, Operations Management and Supply chain Expert'
    // },
    // {
    //   name: ' Michelle Wu',
    //   image: '/team_photos/michelle.png',
    //   position: 'Manufacturing Partner',
    //   info: 'Expert in Industrial Manufacturing Processes and Supply chain'
    // },
    {
      type: 'join team card'
    }
  ]
};

const MeetTeam = () => {
  return (
    <SectionWrapper>
      <SectionHeader dangerouslySetInnerHTML={{ __html: MeetTeamData.title }} id="our-team" />
      <SectionContainer>
        {MeetTeamData.teamMembers.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {index === MeetTeamData.teamMembers.length - 1 && (
                <JoinCard>
                  <JoinTitle>Join</JoinTitle>
                  <JoinDescription>Our Team</JoinDescription>
                  <ViewJobs
                    href="https://thakaa-technologies.breezy.hr/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    view available jobs
                  </ViewJobs>
                </JoinCard>
              )}
              {item.name && (
                <MemberCard>
                  <CoverCard src={item.image} alt={item.name} />
                  <CardText>
                    <TitleCard> {item.name} </TitleCard>
                    <Position> {item.position} </Position>
                    {item.info && <Info>{item.info}</Info>}
                  </CardText>
                </MemberCard>
              )}
            </React.Fragment>
          );
        })}
      </SectionContainer>
    </SectionWrapper>
  );
};

MeetTeam.propTypes = {};

MeetTeam.defaultProps = {};

const SectionWrapper = styled.div`
  padding: 75px 0 51px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(to left, #eaf9f6 0%, #ffffff 100%);
  position: relative;
  overflow: hidden;
  width: 100%;
`;

const SectionHeader = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 45px;
  line-height: 55px;
  color: #2d2d2d;
  font-weight: 500;
  margin: 0 auto;
  max-width: 680px;
  text-align: center;
  overflow: hidden;
  position: relative;
  padding-bottom: 28px;
  @media (max-width: 768px) {
    font-size: 35px;
  }
  span {
    color: #008e8e;
    font-weight: 700;
    text-shadow: none;
  }
  ::after {
    width: 100%;
    margin: 0 auto;
    height: 10px;
    transform: translate(-50%, 0);
    background-image: url('/divider.svg');
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    background-position: center center;
    bottom: 0;
    left: 50%;
  }
`;

const SectionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1180px;
  margin: 32px -16px 0;
  @media (max-width: 1280px) {
    max-width: 90%;
    margin: 32px auto 0;
    justify-content: center;
  }
  @media (max-width: 768px) {
    max-width: 80%;
    justify-content: center;
  }
`;

const MemberCard = styled.div`
  width: 100%;
  max-width: 30%;
  margin: 0 8px 24px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: 8px;
  @media (max-width: 768px) {
    max-width: 42%;
  }
  @media (max-width: 470px) {
    max-width: 100%;
  }
`;

const CoverCard = styled.img`
  margin-bottom: 0 !important;
  height: 100%;
  width: 100%;
  max-height: 360px;
  flex-grow: 1;
  object-fit: cover;
  object-position: center top;
  @media (max-width: 470px) {
    max-height: 280px;
  }
`;

const CardText = styled.div`
  padding: 35px 8px 16px;
  height: auto;
  @media (max-width: 470px) {
    padding: 18px 8px 8px;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;
const TitleCard = styled.h5`
  color: #008e8e;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 8px;
  @media (max-width: 470px) {
    font-size: 18px;
  }
`;

const Position = styled.p`
  color: #0c2546;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  @media (max-width: 470px) {
    font-size: 16px;
  }
`;

const Info = styled.p`
  color: #0c2546;
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 400;
  padding-top: 8px;
  line-height: 1.2;
  text-align: center;
  height: 32px;
  @media (max-width: 768px) {
    height: auto;
  }
`;

const JoinCard = styled.div`
  width: 100%;
  max-width: 30%;
  margin: 0 8px 24px;
  background-color: #008e8e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px;
  border-radius: 8px;
  min-height: 380px;
  @media (max-width: 768px) {
    max-width: 42%;
  }
  @media (max-width: 470px) {
    max-width: 100%;
    min-height: 320px;
  }
`;

const JoinTitle = styled.h5`
  font-weight: 500;
  color: #ffffff;
  font-family: Poppins, sans-serif;
  font-size: 45px;
  margin: 0px;
`;

const JoinDescription = styled.h5`
  color: #ffffff;
  font-family: Poppins, sans-serif;
  font-size: 45px;
  font-weight: 700;
  margin: 0 0 16px;
`;

const ViewJobs = styled.a`
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 21px;
  text-decoration: underline;
  color: white;
`;

export default MeetTeam;
