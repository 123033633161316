import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import AnimSide from '../Animation/AnimSide';

const TestimonialData = {
  title: 'What Scholars Say <span>About Sajdah?</span>',
  subhead:
    'Here are some of many testimonials from prominent figures praising Sajdah and its innovative features.',
  slidesData: [
    {
      authorIcon: '/testimonial_icons/othman_alkhamis.png',
      authorName: 'Dr. Othman Al Khamees',
      authorPosition: 'Jurist, Academic and Scholar',
      title: '“This is a very good use of modern technology for what is beneficial for us.”',
      text:
        'Masha Allah, a modern educational method and the idea itself is very good and I liked it, and this is a very good use of modern technology for what is beneficial for us, especially for new Muslims who do not speak Arabic.'
    },
    {
      authorIcon: '/testimonial_icons/mh_awadi.png',
      authorName: 'Dr. Mohammed Al Awadi',
      authorPosition: 'Islamic Scholar',
      title: '“I ask Allah Almighty to help you in this beautiful innovation.”',
      text:
        'Tangible means, especially after the introduction of new audio and video technology, is the means by which we like to communicate with the world. I ask Allah Almighty to help you in this beautiful innovation for the religion of Allah.'
    },
    {
      authorIcon: '/testimonial_icons/tareq_ suwaidan.png',
      authorName: 'Dr. Tareq Al Suwaidan',
      authorPosition: 'Islamic Researcher',
      title: '“Sajdah is a creative project, and the need for it is big.”',
      text:
        'Sajdah is a creative project, and the need for it is big. It solves a problem not only for new Muslims, also for people who want to complete the recital of the Quran without holding the holy book. May Allah bless you.'
    },
    {
      authorIcon: '/testimonial_icons/mahmood-alam.png',
      authorName: 'Mahmood Alam',
      authorPosition: 'Professor at Integral University',
      title: '“Innovative and Quite Modern.”',
      text:
        'Sajdah is an innovative and quite modern approach to make your Salah error-free. May Allah grant you success in this project and make it beneficial for the ummah.'
    },
    {
      authorIcon: '/testimonial_icons/ahmad-albouainain.png',
      authorName: 'Sheikh Ahmad Albouainain',
      authorPosition: 'Imam and Khatib',
      title: '“Beautiful idea!”',
      text: 'It’s a beautiful idea! There are many people and schools that need it.'
    },
    {
      authorIcon: '/testimonial_icons/sulaiman-bah.png',
      authorName: 'Dr. Sulaiman T. Bah',
      authorPosition: 'Qatar Foundation - Minaretein Mosque',
      title: '“This Innovation Facilitates.”',
      text:
        'Sometimes innovation is something frowned upon when you’re talking about religion, but this innovation facilitates.'
    }
  ]
};

const SajdahTestimonial = () => {
  /* eslint-disable no-unused-vars */
  const [currentSlide, setCurrentSlide] = useState(0);
  /* eslint-enable no-unused-vars */
  const settings = {
    autoplay: false,
    infinite: true,
    centerPadding: '0',
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    className: 'slider variable-width',
    beforeChange: (current, next) => setCurrentSlide(next),
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 5000,
          pauseOnHover: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          autoplay: true,
          autoplaySpeed: 5000,
          slidesToShow: 1,
          slidesToScroll: 1,
          pauseOnHover: false
        }
      }
    ]
  };
  const sliderRef = useRef();

  const gotoNext = () => {
    sliderRef.current.slickNext();
  };

  return (
    <SectionWrapper id="testimonials">
      <SectionHeader dangerouslySetInnerHTML={{ __html: TestimonialData.title }} />
      <SectionSubhead>{TestimonialData.subhead}</SectionSubhead>
      <SectionContainer>
        <AnimSide reversed>
          <MapSide>
            <img src="/testimonail-map.png" alt="testimonial map" />
          </MapSide>
        </AnimSide>
        <SliderSide>
          <SLiderWrapper>
            <Slider ref={sliderRef} {...settings}>
              {TestimonialData.slidesData.map((slide, index) => {
                return (
                  <SlideWrapper className="slide-wrapper" key={index}>
                    <CardTestimonial>
                      <TitleTestimonial>{slide.title}</TitleTestimonial>
                      <TextTestimonial>{slide.text}</TextTestimonial>
                      <AuthorInfo>
                        <AuthorIcon src={slide.authorIcon} alt={slide.title} />
                        <div>
                          <AuthorName>{slide.authorName}</AuthorName>
                          <AuthorPosition>{slide.authorPosition}</AuthorPosition>
                        </div>
                      </AuthorInfo>
                      <Quote>“</Quote>
                    </CardTestimonial>
                  </SlideWrapper>
                );
              })}
            </Slider>
          </SLiderWrapper>
          <ButtonsWrapper style={{ textAlign: 'center' }}>
            <SlickNext onClick={gotoNext}></SlickNext>
          </ButtonsWrapper>
        </SliderSide>
      </SectionContainer>
    </SectionWrapper>
  );
};

SajdahTestimonial.propTypes = {};

SajdahTestimonial.defaultProps = {};

const SectionWrapper = styled.div`
  padding-top: 52px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 48px;
  @media (max-width: 768px) {
    margin: 0 32px;
  }
`;

const SectionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 0 50%;
  margin-top: 50px;
  margin-bottom: 50px;
  align-items: center;
  @media (max-width: 1280px) {
    max-width: 90%;
  }
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    padding: 0;
  }
`;

const SectionHeader = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 45px;
  line-height: 55px;
  color: #2d2d2d;
  font-weight: 500;
  margin: 0 auto;
  max-width: 800px;
  text-align: center;
  overflow: hidden;
  position: relative;
  padding-bottom: 28px;
  span {
    color: #008e8e;
    font-weight: 700;
    text-shadow: none;
  }
  ::after {
    width: 100%;
    max-width: 710px;
    margin: 0 auto;
    height: 10px;
    transform: translate(-50%, 0);
    background-image: url('/divider.svg');
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    background-position: center center;
    bottom: 0;
    left: 50%;
  }
`;

const SectionSubhead = styled.h5`
  font-family: Poppins, sans-serif;
  color: #333333;
  font-size: 18px;
  font-weight: 400;
  margin: 42px 0 0;
  max-width: 721px;
`;

const MapSide = styled.div`
  width: 100%;
  margin-right: 24px;
  img {
    max-width: 100%;
  }
  @media (max-width: 768px) {
    max-width: 100%;
    margin: 0;
  }
`;
const SliderSide = styled.div`
  width: 100%;
  max-width: 50%;
  position: relative;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const SLiderWrapper = styled.div`
  margin: 0 0 40px;
  padding-left: 40px;
  max-width: 530px;
  height: auto;
  position: relative;
  .slick-arrow {
    display: none !important;
  }
  .slick-slide {
    overflow: hidden !important;
  }
  @media (max-width: 768px) {
    padding: 0;
    width: 100%;
    margin: 0 auto 40px;
  }
`;

const SlideWrapper = styled.div`
  width: 100%;
  max-width: 482px;
  display: inline-block;
  position: relative;
  outline: none;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  z-index: 0;
  padding: 0 32px 0 0;
  @media (max-width: 768px) {
    display: block;
    padding: 0;
    margin-right: 12px;
  }
`;

const CardTestimonial = styled.div`
  position: relative;
`;

const TextTestimonial = styled.div`
  text-align: left;
  height: 75px;
  @media (max-width: 470px) {
    height: 160px;
  }
`;

const ButtonsWrapper = styled.div`
  button {
    position: absolute;
    z-index: 10;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    border: none;
    width: 48px;
    height: 48px;
    border-radius: 100%;
    transform-origin: 0 0;
    overflow: hidden;
    color: white;
    outline: none;
    transition: all 0.2s ease-in-out;
    background-color: #008e8e;
    @media (max-width: 470px) {
      display: none;
    }
    :hover {
      transform: scale(1.05) translateY(-50%);
    }
    ::before {
      content: '';
      position: absolute;
      top: 50%;
      display: block;
      width: 9px;
      height: 9px;
    }
  }
`;

const SlickNext = styled.button`
  right: 12px;
  ::before {
    left: 20px;
    transform: translate(-50%, -50%) rotate(-45deg);
    border-right: 1.5px solid white;
    border-bottom: 1.5px solid white;
  }
`;

const TitleTestimonial = styled.h5`
  color: #333333;
  font-family: Poppins, sans-serif;
  font-size: 25px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.1;
  text-align: left;
  margin-bottom: 24px;
  padding-top: 40px;
`;

const AuthorInfo = styled.div`
  margin-top: 40px;
  display: flex;
  div {
    text-align: left;
  }
`;

const AuthorIcon = styled.img`
  border-radius: 100%;
  width: 48px;
  height: 48px;
  margin-right: 15px;
`;
const AuthorName = styled.span`
  color: #008e8e;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  text-align: left;
`;

const AuthorPosition = styled.p`
  color: #333333;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
`;

const Quote = styled.span`
  color: #008e8e;
  font-family: Poppins, sans-serif;
  font-size: 85px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 30px;
  position: absolute;
  top: 0;
  left: 0;
`;

export default SajdahTestimonial;
