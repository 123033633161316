import React from 'react';
import styled from 'styled-components';

const FeaturesData = {
  title: 'Features of Sajdah',
  itemCards: [
    {
      icon: '/prayer.svg',
      image: '/guided.png',
      title: 'Guided Prayer Training',
      text:
        'Sajdah guides you through daily and non-daily prayers in a step-by-step learning experience.'
    },
    {
      icon: '/quran.svg',
      image: '/quaran-cover.png',
      title: 'Quran Reading',
      text:
        'Sajdah’s LED screen helps you read & memorize the Holy Quran while practising your prayers.'
    },
    {
      icon: '/mobile-app.svg',
      image: '/mobile-cover.png',
      title: 'Smart & Connected',
      text:
        'Use the Sajdah app to control the pace of your prayer guides, including chapter transition speed, font size, and much more.'
    },
    {
      icon: '/student.svg',
      image: '/english-arabic.png',
      title: 'Multi-Lingual',
      text:
        'Prayer guides and Quran verses displayed in Arabic, English, and Latin Transliteration, updates will include more languages.'
    }
  ]
};

const SajdahFeatures = () => {
  return (
    <SectionWrapper id="features">
      <SectionHeader dangerouslySetInnerHTML={{ __html: FeaturesData.title }} />
      <FeaturesContainer>
        {FeaturesData.itemCards.map((item, index) => {
          return (
            <FeatureCard key={index}>
              <CardCover>
                <img src={item.image} alt={item.title} />
              </CardCover>
              <CardBody>
                <IconWrapper>
                  <img src={item.icon} alt={item.title} />
                </IconWrapper>
                <TitleCard>{item.title}</TitleCard>
                <TextCard>{item.text}</TextCard>
              </CardBody>
            </FeatureCard>
          );
        })}
      </FeaturesContainer>
    </SectionWrapper>
  );
};

SajdahFeatures.propTypes = {};

SajdahFeatures.defaultProps = {};

const SectionWrapper = styled.div`
  margin-top: 67px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const FeaturesContainer = styled.div`
  width: 100%;
  max-width: 1270px;
  display: flex;
  justify-content: space-between;
  margin: 41px -5px 0;
  flex-wrap: wrap;
  @media (max-width: 1280px) {
    max-width: 90%;
    justify-content: center;
  }
  @media (max-width: 768px) {
    margin: 41px auto 0;
  }
  @media (max-width: 470px) {
    flex-direction: column;
    align-items: center;
  }
`;

const FeatureCard = styled.div`
  width: 100%;
  max-width: 300px;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1280px) {
    max-width: 40%;
    padding: 0 32px;
    margin-bottom: 32px;
  }
  @media (max-width: 768px) {
    max-width: 45%;
    margin-bottom: 32px;
  }
  @media (max-width: 470px) {
    max-width: 310px;
    padding: 0;
  }
`;

const CardCover = styled.div`
  margin-bottom: -6px;
  img {
    width: 100%;
    height: auto;
  }
`;

const CardBody = styled.div`
  border-radius: 0 0 16px 16px;
  border: 1px solid #dedede;
  border-top: none;
  padding: 78px 32px 49px;
  text-align: left;
  position: relative;
`;
const TitleCard = styled.h5`
  color: #333333;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  margin: 0 0 14px;
`;

const TextCard = styled.p`
  color: #333333;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 23px;
  height: 84px;
  margin: 0;
`;

const IconWrapper = styled.div`
  position: absolute;
  width: 96px;
  height: 96px;
  border-radius: 100%;
  top: 0;
  background-color: white;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 3px 4px 65px rgba(0, 0, 0, 0.09);
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 40px;
  }
  ::after {
    width: 80px;
    box-shadow: 3px 4px 65px rgba(0, 0, 0, 0.09);
    height: 80px;
    transform: translate(-50%, -50%);
    background-image: white;
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 100%;
  }
  ::before {
    width: 62px;
    box-shadow: 3px 4px 65px rgba(0, 0, 0, 0.09);
    height: 62px;
    transform: translate(-50%, -50%);
    background-image: white;
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 100%;
  }
`;

const SectionHeader = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 45px;
  line-height: 55px;
  color: #2d2d2d;
  font-weight: 500;
  margin: 0 auto;
  max-width: 600px;
  text-align: center;
  overflow: hidden;
  position: relative;
  padding-bottom: 28px;
  span {
    color: #008e8e;
    font-weight: 700;
    text-shadow: none;
  }
  ::after {
    width: 100%;
    max-width: 410px;
    margin: 0 auto;
    height: 10px;
    transform: translate(-50%, 0);
    background-image: url('/divider.svg');
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    background-position: center center;
    bottom: 0;
    left: 50%;
  }
  @media (max-width: 768px) {
    font-size: 35px;
  }
`;

export default SajdahFeatures;
