import React from 'react';
import styled from 'styled-components';
import AnimeUp from '../Animation/AnimeUp';

const PartnersData = {
  title: 'Our <span>Partners</span>',
  partnershipItems: [
    {
      icon: '/warranty.png',
      text: '12 Months Limited Warranty'
    },
    {
      icon: '/features.png',
      text: 'Special Features'
    },
    {
      icon: '/bag.png',
      text: 'Bag Included'
    },
    {
      icon: '/age.png',
      text: 'Customer Friendly Service'
    }
  ]
};

const PartnersSection = () => {
  return (
    <SectionWrapper>
      <SectionHeader dangerouslySetInnerHTML={{ __html: PartnersData.title }} />
      <PartnersContainer>
        <MainPartner>
          <PartnerItemHeader>Main Partner:</PartnerItemHeader>
          <PartnersItem>
            <img src="/main-partner-logo.png" alt="Qatar Science and Technology Park (QSTP)" />
          </PartnersItem>
        </MainPartner>
        <OtherPartners>
          <PartnerItemHeader>Supported by</PartnerItemHeader>
          <PartnersItem className="partners-container">
            <img src="/supported_by/qf-logo.png" alt="Qatar Foundation" />
            <img src="/supported_by/sos-logo.png" alt="Stars of Science" />
            <img src="/supported_by/qsc-logo.png" alt="Qatar Scientific Club" />
            <img src="/supported_by/qdb-logo.png" alt="Qatar Development Bank - Advisory" />
          </PartnersItem>
        </OtherPartners>
      </PartnersContainer>
      <PartnershipWrapper>
        {/* <PartnersSubhead>Partnership With Us</PartnersSubhead> */}
        <PartnershipContainer>
          <PartnershipList>
            {PartnersData.partnershipItems.map((item, index) => {
              return (
                <AnimeUp key={index} delay={index / 10 + 0.1}>
                  <PartnershipItem>
                    <img src={item.icon} alt="partner" />
                    <span>{item.text}</span>
                  </PartnershipItem>
                </AnimeUp>
              );
            })}
          </PartnershipList>
        </PartnershipContainer>
      </PartnershipWrapper>
    </SectionWrapper>
  );
};

PartnersSection.propTypes = {};

PartnersSection.defaultProps = {};

const SectionWrapper = styled.div`
  margin-top: 67px;
  text-align: center;
  width: 100%;
`;

const SectionHeader = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 45px;
  line-height: 55px;
  color: #2d2d2d;
  font-weight: 500;
  margin: 0 auto;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  position: relative;
  padding-bottom: 28px;
  @media (max-width: 768px) {
    font-size: 35px;
  }
  span {
    color: #008e8e;
    font-weight: 700;
    text-shadow: none;
  }
  ::after {
    width: 100%;
    height: 10px;
    background-image: url('/divider.svg');
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    background-position: center center;
    bottom: 0;
    left: 0;
  }
`;

const PartnersContainer = styled.div`
  width: 100%;
  max-width: 1127px;
  margin: 60px auto 47px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1280px) {
    max-width: 90%;
  }
  @media (max-width: 470px) {
    flex-direction: column;
  }
`;

const PartnerItemHeader = styled.h5`
  color: #333333;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
`;

const MainPartner = styled.div`
  width: 25%;
  text-align: left;
  @media (max-width: 768px) {
    width: 20%;
    padding-left: 24px;
  }
  @media (max-width: 470px) {
    width: 60%;
    margin: 0 auto;
    padding-left: 0;
  }
`;

const OtherPartners = styled.div`
  width: 74%;
  text-align: left;
  @media (max-width: 768px) {
    width: 70%;
    padding-right: 24px;
  }
  @media (max-width: 470px) {
    width: 60%;
    margin: 0 auto;
    padding-right: 0;
    .partners-container {
      display: flex;
      flex-direction: column;
      img {
        margin-bottom: 16px;
      }
    }
  }
`;

const PartnersItem = styled.div`
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 39px 37px;
  border: 1px solid #f0f0f0;
  @media (max-width: 768px) {
    padding: 16px 24px;
  }
  img {
    max-width: 150px;
    height: 80px;
    margin: 0 auto;
    @media (max-width: 1280px) {
      max-width: 120px;
      height: 60px;
    }
    @media (max-width: 768px) {
      max-width: 100px;
      height: 50px;
    }
  }
`;

const PartnershipWrapper = styled.div``;

// const PartnersSubhead = styled.h5`
//   text-transform: uppercase;
//   color: #008e8e;
//   font-family: Poppins, sans-serif;
//   font-size: 18px;
//   font-weight: 500;
//   line-height: 18px;
//   margin-bottom: 70px;
// `;

const PartnershipContainer = styled.div`
  width: 100%;
  background-image: linear-gradient(to left, #eaf9f6 0%, #ffffff 100%);
  padding: 50px 0 62px;
  @media (max-width: 470px) {
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
`;

const PartnershipList = styled.div`
  width: 100%;
  max-width: 1026px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1280px) {
    max-width: 90%;
  }
  @media (max-width: 768px) {
    padding: 0 24px;
    width: auto;
  }
  @media (max-width: 470px) {
    flex-direction: column;
  }
`;

const PartnershipItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    max-width: 20%;
  }
  @media (max-width: 470px) {
    max-width: 100%;
    margin-bottom: 24px;
  }
  img {
    width: 114px;
    margin-bottom: 28px;
    @media (max-width: 768px) {
      max-width: 80px;
    }
  }
  span {
    color: #292929;
    font-family: Poppins, sans-serif;
    font-size: 17px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: normal;
  }
`;

export default PartnersSection;
