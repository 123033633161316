import React, { useEffect } from "react"
import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"

const AnimeUp = ({ children, delay, exit, reversed, className }) => {
  const controls = useAnimation()
  
  const [ref, inView] = useInView({
    rootMargin: "-100px",
  })

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
    if (exit) {
      if(!inView) {
        controls.start("exit")
      }
    }
  }, [controls, inView, exit])
  let xPos 
  if (reversed) {
    xPos=-30
  } else {
    xPos = 30
  }
  
  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      className={className}
      variants={{
        visible: {
          opacity: 1,
          y: 0,
          transition: { delay:`${delay}`,duration: 1, ease: [0.6, 0.05, -0.01, 0.9] },
        },
        hidden: { opacity: 0, y: xPos },
        
      }}
    >
      {children}
    </motion.div>
  )
}

export default AnimeUp