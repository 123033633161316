import React from "react";
import styled from "styled-components";
// import AnimSide from "../Animation/AnimSide";
const SmartData = {
  title: "<span>Smart Control</span> & Customization",
  subhead:
    "Weve built Sajdah in a way that lets every Muslim customize their praying experience to fit their needs. Simply pair your smartphone or Apple Watch to your Sajdah, and enjoy control & customization options as shown below. Multiple devices can connect to one Sajdah at the same time."
};

const SmartControl = () => {
  return (
    <SectionWrapper>
      <SectionHeader dangerouslySetInnerHTML={{ __html: SmartData.title }} />
      <SectionSubhead>{SmartData.subhead}</SectionSubhead>
      <SectionContainer>
        {/* <AnimSide reversed> */}
        <LeftSide>
          <IconItem>
            <img src="/smart-control/type.png" alt="type" />
            <p>Fit the font size &amp; text speed to your needs</p>
          </IconItem>
          <IconItem>
            <img src="/smart-control/watch.png" alt="watch" />
            <p>Control the LED screen brightness</p>
          </IconItem>
          <IconItem>
            <img src="/smart-control/volume-up.png" alt="volume-up" />
            <p>Change the speaker volume</p>
          </IconItem>
        </LeftSide>
        {/* </AnimSide> */}
        <ImageContainer>
          <img src="/smart-image.png" alt="Sajdah on Smart Devices" />
        </ImageContainer>
        {/* <AnimSide> */}
          <RightSide>
            <IconItem>
              <img src="/smart-control/muslim.png" alt="muslim" />
              <p>Find the perfect the transition speed between Surahs</p>
            </IconItem>
            <IconItem>
              <img src="/smart-control/touch.png" alt="touch" />
              <p>Apple Watch users: Control transitions with your fingertips!</p>
            </IconItem>
          </RightSide>
        {/* </AnimSide> */}
      </SectionContainer>
      <FooterInfo>
        <DownloadIconsWrapper>
          <a
            href="https://play.google.com/store/apps/details?id=com.getsajdah.sajdah.android"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/google-play.svg" alt="Get Sajdah from Google Play Store"/>
          </a>
          <a
            href="https://apps.apple.com/us/app/sajdah-prayer-guide-times/id1557291793"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/app-store.svg" alt="Get Sajdah from Apple App Store"/>
          </a>
        </DownloadIconsWrapper>
        <p>Ad-free experience with privacy &amp; data protection.</p>
      </FooterInfo>
    </SectionWrapper>
  );
};

SmartControl.propTypes = {};

SmartControl.defaultProps = {};

const SectionWrapper = styled.div`
  padding-top: 52px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const SectionContainer = styled.div`
  width: 100%;
  max-width: 1351px;
  display: flex;
  justify-content: space-around;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: 0 50%;
  padding: 0 32px;
  margin-top: 50px;
  margin-bottom: 50px;
  align-items: center;
  @media (max-width: 1280px) {
    max-width:90%;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 0;
    padding: 0;
  }
`;

const SectionHeader = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 45px;
  line-height: 55px;
  color: #2d2d2d;
  font-weight: 500;
  margin: 0 auto;
  max-width: 800px;
  text-align: center;
  overflow: hidden;
  position: relative;
  padding-bottom: 28px;
  @media (max-width: 768px) {
    font-size: 35px;
    max-width: 300px;
  }
  span {
    color: #008e8e;
    font-weight: 700;
    text-shadow: none;
  }
  ::after {
    width: 100%;
    max-width: 710px;
    margin: 0 auto;
    height: 10px;
    transform: translate(-50%, 0);
    background-image: url("/divider.svg");
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-position: center center;
    bottom: 0;
    left: 50%;
  }
`;

const SectionSubhead = styled.h5`
  font-family: Poppins, sans-serif;
  color: #333333;
  font-size: 18px;
  font-weight: 400;
  margin: 42px 0 0;
  max-width: 921px;
  @media (max-width: 768px) {
    max-width: 470px;
    padding: 0 24px;
  }
`;

const LeftSide = styled.div`
  width: 100%;
  max-width: 200px;
  max-height: 564px;
  display: flex;
  justify-content: space-between;
  height: auto;
  flex-direction: column;
  div {
    margin: 30px 0;
  }
  @media (max-width: 768px) {
    flex-direction: row;
    max-width: 90%;
    margin: 0 auto;
    justify-content: center;
    div {
      margin: 16px 0;
      max-width: 30%;
      p {
        font-size: 14px;
      }
    }
  }
  @media (max-width: 470px) {
    justify-content: space-between;
  }
`;

const RightSide = styled.div`
  width: 100%;
  max-width: 200px;
  display: flex;
  justify-content: space-between;
  height: auto;
  flex-direction: column;
  max-height: 460px;
  div {
    margin: 50px 0;
  }
  @media (max-width: 768px) {
    flex-direction: row;
    max-width: 90%;
    margin: 0 auto;
    justify-content: center;
  }
`;

const ImageContainer = styled.div`
  width: 676px;
  height: 676px;
  border-radius: 100%;
  box-shadow: 3px 4px 65px rgba(0, 0, 0, 0.09);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1280px) {
    width: 500px;
    height: 500px;
  }

  @media (max-width: 470px) {
    margin: 32px 0;
    width: 350px;
    height: 350px;
  }
  ::after {
    width: 579px;
    box-shadow: 3px 4px 65px rgba(0, 0, 0, 0.09);
    height: 579px;
    transform: translate(-50%, -50%);
    background-image: white;
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 100%;
    @media (max-width: 1280px) {
      width: 500px;
      height: 500px;
    }
    @media (max-width: 470px) {
      width: 300px;
      height: 300px;
    }
  }
  ::before {
    width: 481px;
    box-shadow: 3px 4px 65px rgba(0, 0, 0, 0.09);
    height: 481px;
    transform: translate(-50%, -50%);
    background-image: white;
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 100%;
    z-index: 20;
    @media (max-width: 1280px) {
      width: 400px;
      height: 400px;
    }
    @media (max-width: 470px) {
      width: 250px;
      height: 250px;
    }
  }
  img {
    position: relative;
    z-index: 100;
    width: 100%;
    max-width: 470px;
    @media (max-width: 1280px) {
      max-width: 420px;
    }
    @media (max-width: 768px) {
      max-width: 380px;
    }
    @media (max-width: 470px) {
      max-width: 300px;
    }
  }
`;

const IconItem = styled.div`
  width: 100%;
  max-width: 200px;
  @media (max-width: 470px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    img {
      max-width: 50%;
      max-height: 60px;
    }
  }
  p {
    @media (max-width: 470px) {
      height: 70px;
    }
  }
`;

const FooterInfo = styled.div`
  width: 100%;
  max-width: 356px;
  p {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    width: 100%;
  }
`;

const DownloadIconsWrapper = styled.div`
  width: 100%;
  max-width: 346px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    height: auto;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 160px;
  }
  img {
    width: 100%;
    max-width: 160px;
  }
`;

export default SmartControl;
