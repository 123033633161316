// https://chaseohlson.com/hubspot-forms-gatsby
// import { Link } from "gatsby"
import React, { useState } from 'react';
import styled from 'styled-components';
import AnimeUp from '../Animation/AnimeUp';

const ContactData = {
  title: 'Contact <span>Us</span>',
  subhead: 'Get in touch with Sajdah team. We would love to hear you.',
  thankYouMessage: 'Thank you! Your message has been sent successfully.'
};

const ContactForm = () => {
  const [formState, setFormState] = useState({
    firstname: '',
    lastname: '',
    email: '',
    message: '',
    subject: ''
  });
  /* eslint-disable no-unused-vars */
  const [isLoading, setIsLoading] = useState(false);
  /* eslint-enable no-unused-vars */
  const [isSuccessful, setIsSuccessful] = useState(false);

  const handleChange = event => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = event => {
    if (event) event.preventDefault();
    const postUrl = `/.netlify/functions/create-support-ticket`;

    setIsLoading(true);

    const body = {
      email: formState.email,
      subject: formState.subject,
      firstname: formState.firstname,
      lastname: formState.lastname,
      comment: formState.message
    };
    fetch(postUrl, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json, application/xml, text/plain, text/html, *.*'
      })
    })
      .then(async response => {
        if (response.ok) {
          setIsSuccessful(true);
        } else {
          setIsSuccessful(false);
          console.error(response);
          alert('Error occurred, code: ' + response.status);
        }
      })
      .catch(err => {
        console.error(err);
        alert(err.message);
        setIsSuccessful(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <SectionWrapper>
        <SectionHeader dangerouslySetInnerHTML={{ __html: ContactData.title }} id="contact-us" />
        <SectionSubhead>
          {ContactData.subhead}
          <br />
          Are you looking for distribution? <a href="/distribution">Use this form instead</a>.
        </SectionSubhead>
        <ContactContainer>
          <AnimeUp className="anim">
            <SubscribeWrapper>
              {isSuccessful === false && (
                <FormWrapper>
                  <Form
                    method="post"
                    name="contact"
                    id="contact-form-homepage"
                    onSubmit={handleSubmit}
                  >
                    <StyledInput
                      placeholder="Enter your first name"
                      name="firstname"
                      type="text"
                      required
                      onChange={handleChange}
                      value={formState.firstname}
                    />
                    <StyledInput
                      placeholder="Enter your last name"
                      name="lastname"
                      type="text"
                      required
                      onChange={handleChange}
                      value={formState.lastname}
                    />
                    <StyledInputEmail
                      placeholder="Enter your email"
                      name="email"
                      type="email"
                      required
                      onChange={handleChange}
                      value={formState.email}
                    />
                    <StyledInputSubject
                      placeholder="Enter your subject"
                      name="subject"
                      type="subject"
                      required
                      onChange={handleChange}
                      value={formState.subject}
                    />
                    <StyledTextarea
                      name="message"
                      type="text"
                      placeholder="Your message"
                      required
                      onChange={handleChange}
                      value={formState.message}
                    />
                    {isLoading === false && (
                      <StyledButton type="submit" name="submit">
                        Get in touch
                      </StyledButton>
                    )}
                  </Form>
                  {isLoading === true && (
                    <LoadingMessage>
                      <Spinner />
                    </LoadingMessage>
                  )}
                </FormWrapper>
              )}
              {isSuccessful === true && (
                <ThankYouMessage>{ContactData.thankYouMessage}</ThankYouMessage>
              )}
            </SubscribeWrapper>
          </AnimeUp>
        </ContactContainer>
      </SectionWrapper>
    </>
  );
};

const ThankYouMessage = styled.div`
  text-align: center;
  width: 100%;
`;

const LoadingMessage = styled.div`
  text-align: left;
  margin-top: 10px;
`;

const Spinner = styled.div`
  font-size: 5px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(73, 70, 98, 0.2);
  border-right: 1.1em solid rgba(73, 70, 98, 0.2);
  border-bottom: 1.1em solid rgba(73, 70, 98, 0.2);
  border-left: 1.1em solid #494662;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  border-radius: 50%;
  width: 5em;
  height: 5em;

  ::after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @media (max-width: 470px) {
    margin: 0 auto;
  }
`;

const SectionWrapper = styled.div`
  padding-top: 52px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #f5f7fd;
`;

const ContactContainer = styled.div`
  width: 100%;
  max-width: 970px;
  display: flex;
  flex-wrap: wrap;
  margin: 50px auto;
  @media (max-width: 1280px) {
    max-width: 90%;
  }
  @media (max-width: 768px) {
    margin-top: 40px;
  }
  @media (max-width: 470px) {
    margin-bottom: 20px;
  }
  .anim {
    width: 100%;
    margin: 0 auto;
  }
`;

const SectionHeader = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 45px;
  line-height: 55px;
  color: #2d2d2d;
  font-weight: 500;
  margin: 0 auto;
  max-width: 600px;
  text-align: center;
  overflow: hidden;
  position: relative;
  padding-bottom: 28px;
  span {
    color: #008e8e;
    font-weight: 700;
    text-shadow: none;
  }
  ::after {
    width: 100%;
    max-width: 410px;
    margin: 0 auto;
    height: 10px;
    transform: translate(-50%, 0);
    background-image: url('/divider.svg');
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    background-position: center center;
    bottom: 0;
    left: 50%;
  }
  @media (max-width: 768px) {
    font-size: 35px;
  }
`;

const SectionSubhead = styled.h5`
  font-family: Poppins, sans-serif;
  color: #333333;
  font-size: 18px;
  font-weight: 400;
  margin: 42px 0 0;
  max-width: 721px;
  @media (max-width: 768px) {
    max-width: 80%;
    margin: 24px auto 0;
  }
`;

const SubscribeWrapper = styled.div`
  width: 100%;
  overflow: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 36px;
  background-color: #ffffff;
  box-shadow: 3px 4px 70px rgba(0, 0, 0, 0.06);
  @media (max-width: 768px) {
    flex-direction: column;
    margin: 0 auto;
  }
  @media (max-width: 470px) {
    max-width: 80%;
    padding: 24px;
  }
`;

const FormWrapper = styled.div`
  width: 100%;
`;

const Form = styled.form`
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 470px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledInput = styled.input`
  height: 15px;
  width: 100%;
  max-width: 42%;
  color: #292929;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  line-height: 15px;
  font-weight: 300;
  background: transparent;
  padding: 23px 25px;
  border: 1px solid #e5e5e5;
  ::-webkit-input-placeholder {
    opacity: 0.6;
    color: #292929;
  }
  &:focus {
    outline: none;
    box-shadow: 0px 0px 2px #008e8e;
  }
  @media (max-width: 768px) {
    padding: 18px 22px;
  }
  @media (max-width: 470px) {
    max-width: 80%;
    margin: 0 auto 19px;
  }
`;

const StyledInputEmail = styled.input`
  height: 15px;
  width: 100%;
  color: #292929;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  line-height: 15px;
  font-weight: 300;
  background: transparent;
  padding: 23px 25px;
  margin: 20px 0 0 0;
  border: 1px solid #e5e5e5;
  ::-webkit-input-placeholder {
    opacity: 0.6;
    color: #292929;
  }
  &:focus {
    outline: none;
    box-shadow: 0px 0px 2px #008e8e;
  }
  @media (max-width: 768px) {
    padding: 18px 22px;
  }
  @media (max-width: 470px) {
    max-width: 80%;
    margin: 0 auto 19px;
  }
`;

const StyledInputSubject = styled.input`
  height: 15px;
  width: 100%;
  color: #292929;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  line-height: 15px;
  font-weight: 300;
  background: transparent;
  padding: 23px 25px;
  margin: 20px 0 0 0;
  border: 1px solid #e5e5e5;
  ::-webkit-input-placeholder {
    opacity: 0.6;
    color: #292929;
  }
  &:focus {
    outline: none;
    box-shadow: 0px 0px 2px #008e8e;
  }
  @media (max-width: 768px) {
    padding: 18px 22px;
  }
  @media (max-width: 470px) {
    max-width: 80%;
    margin: 0 auto 19px;
  }
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  color: #292929;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  line-height: 15px;
  font-weight: 300;
  background: transparent;
  padding: 23px 25px;
  border: 1px solid #e5e5e5;
  margin: 20px 0;
  height: 150px;
  ::-webkit-input-placeholder {
    opacity: 0.6;
    color: #292929;
  }
  &:focus {
    outline: none;
    box-shadow: 0px 0px 2px #008e8e;
  }
  @media (max-width: 768px) {
    padding: 18px 22px;
  }
  @media (max-width: 470px) {
    max-width: 80%;
    margin: 0 auto 19px;
  }
`;

const StyledButton = styled.button`
  background-color: #008e8e;
  color: white;
  border-radius: 4px;
  outline: none;
  border: none;
  padding: 16px 12px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  cursor: pointer;
  width: 100%;
  max-width: 240px;
  margin-top: 16px;
  :hover {
    opacity: 0.9;
  }
  @media (max-width: 470px) {
    padding: 24px 12px;
  }
`;

ContactForm.propTypes = {};

ContactForm.defaultProps = {};

export default ContactForm;
