/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';

const HeroData = {
  title: 'World’s First <span>Smart Educational Prayer Rug</span> For Every Muslim',
  imageGadgets: '/gadgets.png',
  sajdahImage: '/sajdah-img.png',
  icon: '/launch-good-black.svg',
  titleCta: 'Available now for only $499',
  textCta:
    'Be one of the first to get access to <span>Sajdah</span> at an exclusive <span>51% off!</span>',
  text:
    "Sajdah teaches new Muslims how to pray properly without any worries. Muslims can also improve their prayer experience by reading the Qur'an from Sajdah's LED screen during Taraweeh, Qyam, and other prayers."
};

const HeroSectionBuy = () => {
  const HeroImagesBuy = useStaticQuery(graphql`
    query HeroImagesBuy {
      gadgets: file(absolutePath: { regex: "/images/gadgets.png/" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sajdah: file(absolutePath: { regex: "/images/before-april-21.png/" }) {
        childImageSharp {
          fluid(maxWidth: 1160) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <HeroWrapper id="home">
      <TextSide>
        <TextContainer>
          <HeroTitle dangerouslySetInnerHTML={{ __html: HeroData.title }} />
          <HeroText>{HeroData.text}</HeroText>
        </TextContainer>

        <Image fluid={HeroImagesBuy.sajdah.childImageSharp.fluid} alt="sajdah-image" />
      </TextSide>
      <ImageSide>
        <Image
          fluid={HeroImagesBuy.gadgets.childImageSharp.fluid}
          alt="konwledgable muslims"
          className="gadgets-image"
        />
        <Arrow src="/arrow-deco.svg" />
        <img src="/hero-mobile-1.png" alt="hero-mobile" className="hero-mobile-first" />
        <img src="/hero-mobile-2.png" alt="hero-mobile" className="hero-mobile-second" />
      </ImageSide>
      <BannerCtaWrapper>
        <BannerHeader>
          <h5>Available now for only $499</h5>
          <p>FREE DELIVERY within Qatar</p>
        </BannerHeader>
        <a href="https://shop.getsajdah.com/">
          <ButtonCta>Get Sajdah Now</ButtonCta>
        </a>
      </BannerCtaWrapper>
    </HeroWrapper>
  );
};

HeroSectionBuy.propTypes = {
  siteTitle: PropTypes.string
};

HeroSectionBuy.defaultProps = {
  siteTitle: ``
};

const HeroWrapper = styled.div`
  width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;
  background-image: linear-gradient(to left, #eaf9f6 0%, #ffffff 100%);
  position: relative;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  @media (max-width: 470px) {
    padding-bottom: 300px;
  }
`;

const TextSide = styled.div`
  width: 100%;
  max-width: 60%;
  display: flex;
  margin-right: -10%;
  position: relative;
  z-index: 100;
  padding-bottom: 400px;
  .gatsby-image-wrapper {
    width: 100%;
    max-width: 863px;
    position: absolute !important;
    bottom: 100px;
    left: 0;
    @media (max-width: 768px) {
      right: 0;
      bottom: 30px;
      width: 120%;
      height: auto;
    }
    @media (max-width: 470px) {
      right: 0;
      bottom: 50px;
      width: 90%;
      height: auto;
    }
  }
  @media (max-width: 768px) {
    max-width: 100%;
    padding-bottom: 300px;
  }
  @media (max-width: 470px) {
    max-width: 100%;
    padding-bottom: 200px;
  }
`;

const ImageSide = styled.div`
  width: 100%;
  max-width: 50%;
  height: auto;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  position: relative;
  @media (max-width: 768px) {
    min-height: auto;
    max-width: 100%;
  }
  @media (max-width: 470px) {
    justify-content: space-between;
    flex-direction: row;
    height: 100%;
    min-height: 200px;
  }
  .desktop-gadgets {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .gatsby-image-wrapper {
    width: 1066px;
    height: auto;
    position: absolute !important;
    right: -250px;
    top: 0%;
    img {
      width: 100%;
      max-width: 1066px;
      position: absolute !important;
    }
    left: 0;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .sajdah {
    @media (max-width: 768px) {
      position: relative;
    }
  }
  .hero-mobile-first {
    display: none;
    @media (max-width: 470px) {
      display: block;
      max-width: 37%;
      position: absolute;
      left: 10%;
      bottom: -10%;
      z-index: 100;
    }
  }
  .hero-mobile-second {
    display: none;
    @media (max-width: 470px) {
      display: block;
      max-width: 37%;
      position: absolute;
      right: 10%;
      bottom: -10%;
      z-index: 100;
    }
  }
`;

// const GadgetsImage = styled.img`
//   width: 1066px;
//   height: auto;
//   position: absolute;
//   right: -250px;
//   top: 40%;
//   transform: translate(0, -50%);
//   padding-top: 100px;
//   @media (max-width: 1440px) {
//     max-width: 960px;
//   }
//   @media (max-width: 1280px) {
//     max-width: 70vw;
//   }
// `;

// const SajdahImage = styled.img`
//   width: 100%;
//   max-width: 863px;
//   position: absolute;
//   bottom: 150px;
//   left: 0;
//   @media (max-width: 768px) {
//     right: 0;
//     bottom: 150px;
//     width: 120%;
//     height: auto;
//   }
//   @media (max-width: 470px) {
//     right: 0;
//     bottom: 50px;
//     width: 120%;
//     height: auto;
//   }
// `;

const Arrow = styled.img`
  width: 65px;
  position: absolute;
  bottom: 200px;
  left: 50%;
  transform: scale(-1, 1);
  @media (max-width: 768px) {
    display: none;
  }
`;

const TextContainer = styled.div`
  width: 100%;
  max-width: 620px;
  padding: 80px 0 0 200px;
  @media (max-width: 1440px) {
    padding: 40px 0 0 100px;
  }
  @media (max-width: 1280px) {
    padding: 40px 0 0 40px;
  }
  @media (max-width: 768px) {
    padding: 20px 0 0 0;
    max-width: 90%;
    margin: 0 auto;
  }
  @media (max-width: 470px) {
    padding: 0 24px;
  }
`;

const HeroTitle = styled.div`
  color: #2d2d2d;
  font-family: Poppins, sans-serif;
  font-size: 60px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: normal;
  line-height: 75px;
  margin-bottom: 47px;
  @media (max-width: 1440px) {
    margin-bottom: 24px;
  }
  @media (max-width: 1280px) {
    font-size: 45px;
  }
  @media (max-width: 768px) {
    font-size: 35px;
    line-height: 45px;
    margin-top: 24px;
  }
  @media (max-width: 470px) {
    max-width: 100%;
  }
  span {
    color: #008e8e;
    font-family: Poppins, sans-serif;
    font-weight: 700;
  }
`;

const HeroText = styled.p`
  max-width: 468px;
  color: #292929;
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: 27px;
  margin-bottom: 60px;
  @media (max-width: 1440px) {
    margin-bottom: 30px;
  }
  @media (max-width: 470px) {
    max-width: 100%;
  }
`;

const SubscribeWrapper = styled.div`
  width: 100%;
  max-width: 70%;
  overflow: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 36px;
  background-color: #009196;
  box-shadow: 3px 4px 70px rgba(0, 0, 0, 0.06);
  position: absolute;
  bottom: 12px;
  z-index: 100;
  left: 50%;
  transform: translate(-50%, 0);
  @media (max-width: 768px) {
    flex-direction: column;
    // margin-bottom: -24px;
  }
  @media (max-width: 470px) {
    max-width: 80%;
    padding: 15px 24px;
  }
`;

const BannerCtaWrapper = styled.div`
  width: 100%;
  max-width: 70%;
  overflow: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 36px;
  box-shadow: 19px 24px 16px rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  position: absolute;
  bottom: 12px;
  z-index: 100;
  left: 50%;
  transform: translate(-50%, 0);
  @media (max-width: 768px) {
    flex-direction: column;
    // margin-bottom: -24px;
  }
  @media (max-width: 470px) {
    max-width: 80%;
    padding: 15px 24px;
  }
`;

const BannerHeader = styled.div`
  display: flex;
  flex-direction: column;
  h5 {
    color: #292929;
    font-family: Poppins, sans-serif;
    font-size: 22px;
    font-weight: 500;
    margin: 0px 14px 0px;
  }
  p {
    color: #292929;
    font-family: Poppins, sans-serif;
    margin: 0px 14px 0px;
  }
  @media (max-width: 470px) {
    flex-direction: column;
    align-items: center;
    h5 {
      margin: 8px 0;
    }
    img {
      margin-bottom: 25px;
    }
  }
`;

const BannerText = styled.div`
  color: #2d2d2d;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 18px;
  span {
    color: #008e8e;
    font-family: Poppins, sans-serif;
    font-weight: 600;
  }
`;

const ButtonCta = styled.button`
  padding: 25px 45px;
  color: #ffffff;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  text-transform: uppercase;
  background-color: #008e8e;
  outline: none;
  border: none;
  cursor: pointer;
`;

const FormInfo = styled.div`
  width: 100%;
  max-width: 200px;
`;

const FormWrapper = styled.div`
  width: 100%;
  max-width: 780px;
`;

const TitleForm = styled.h3`
  color: white;
  font-family: Poppins, sans-serif;
  font-size: 17px;
  font-weight: 500;
  margin-top: 0;
  padding-right: 10px;
  @media (max-width: 470px) {
    padding-right: 0;
  }
`;

const Form = styled.form`
  width: 100%;
  // border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  @media (max-width: 470px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledInput = styled.input`
  height: 15px;
  width: 100%;
  max-width: 530px;
  color: #fafafa;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  line-height: 15px;
  font-weight: 300;
  background: transparent;
  padding: 23px 25px;
  border: 1px solid #e5e5e5;
  ::-webkit-input-placeholder {
    opacity: 0.6;
    color: #fafafa;
  }
  @media (max-width: 768px) {
    padding: 18px 22px;
  }
  @media (max-width: 470px) {
    max-width: 80%;
    margin: 0 auto 19px;
  }
`;

const StyledButton = styled.button`
  background-color: #fff;
  color: black;
  border-radius: 4px;
  outline: none;
  border: none;
  padding: 8px 12px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  cursor: pointer;
  width: 100%;
  max-width: 240px;
  margin-left: 8px;
  :hover {
    opacity: 0.9;
  }
  @media (max-width: 470px) {
    padding: 24px 12px;
  }
`;

export default HeroSectionBuy;
