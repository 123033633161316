import React from 'react';
import styled from 'styled-components';
import AnimeUp from '../Animation/AnimeUp';
const SeeData = {
  title: 'As <span>seen</span> on',
  icons: [
    '/see-on-logos/Washington-Post-logo-618x400.png',
    '/see-on-logos/al_jazeera-logo.png',
    '/see-on-logos/Layer_21.png',
    '/see-on-logos/1200px-TRT_logo.png',
    '/see-on-logos/rns_400x400.jpeg',
    '/see-on-logos/associated-press-logo.png',
    '/see-on-logos/Anadolu_Agency_Logo.jpg',
    '/see-on-logos/arabhardware.png',
    '/see-on-logos/ech-logo.svg',
    '/see-on-logos/Layer_18.png'
  ]
};

const SeeOn = () => {
  return (
    <SectionWrapper id="media">
      <SectionHeader dangerouslySetInnerHTML={{ __html: SeeData.title }} />
      <SeeOnContainer>
        {SeeData.icons.map((item, index) => {
          return (
            <AnimeUp key={index} delay={index / 10 + 0.1}>
              <IconItem>
                <img src={item} alt="icon" width="80px" />
              </IconItem>
            </AnimeUp>
          );
        })}
      </SeeOnContainer>
    </SectionWrapper>
  );
};

SeeOn.propTypes = {};

SeeOn.defaultProps = {};

const SectionWrapper = styled.div`
  padding-top: 52px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const SeeOnContainer = styled.div`
  width: 100%;
  max-width: 970px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-left: -30px;
  margin-right: -30px;
  @media (max-width: 768px) {
    margin-top: 40px;
  }
  @media (max-width: 470px) {
    margin-bottom: 20px;
  }
`;

const SectionHeader = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 45px;
  line-height: 55px;
  color: #2d2d2d;
  font-weight: 500;
  margin: 0 auto;
  max-width: 600px;
  text-align: center;
  overflow: hidden;
  position: relative;
  padding-bottom: 28px;
  span {
    color: #008e8e;
    font-weight: 700;
    text-shadow: none;
  }
  ::after {
    width: 100%;
    max-width: 410px;
    margin: 0 auto;
    height: 10px;
    transform: translate(-50%, 0);
    background-image: url('/divider.svg');
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    background-position: center center;
    bottom: 0;
    left: 50%;
  }
  @media (max-width: 768px) {
    font-size: 35px;
  }
`;

const IconItem = styled.div`
  width: 125px;
  height: 125px;
  border-radius: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 3px 4px 29px rgba(0, 0, 0, 0.05);
  margin: 44px 30px;
  @media (max-width: 768px) {
    width: 110px;
    height: 110px;
    margin: 44px 15px;
  }
  @media (max-width: 470px) {
    width: 90px;
    height: 90px;
    margin: 20px;
    img {
      max-width: 60px;
    }
  }
`;

export default SeeOn;
