import React from "react";
import styled from "styled-components";
import AnimSide from "../Animation/AnimSide";
const MoreComfortData = {
  title: "More <span>Comfortable Prayer</span> Experience",
  subhead:
    "Sajdah features an anti-slip layer and a memory foam layer that makes your prayer experience more comfortable. We see this as a great benefit for Muslims with knee or joint pain."
};

const MoreComfort = () => {
  return (
    <SectionWrapper>
      <AnimSide reversed>
        <ItemComfort>
          <TitleItem>Anti-slip layer</TitleItem>
          <ImageItem
            src="/prayer-2.svg"
            alt="sajdah anti-slip layer"
            className="outline"
          />
        </ItemComfort>
      </AnimSide>
      
      <TextComfort>
        <SectionHeader
          dangerouslySetInnerHTML={{ __html: MoreComfortData.title }}
        />
        <SectionSubhead>{MoreComfortData.subhead}</SectionSubhead>
      </TextComfort>
      <AnimSide>
        <ItemComfort>
          <TitleItem>Memory Foam</TitleItem>
          <ImageItem src="/prayer-1.svg" alt="sajdah memory foam" />
        </ItemComfort>
      </AnimSide>
     
      <MobileImages>
        <img src="/mob-sajd1.png" alt="mobile-1" />
        <img src="/mob-sajd2.png" alt="mobile-2" />
      </MobileImages>
    </SectionWrapper>
  );
};

MoreComfort.propTypes = {};

MoreComfort.defaultProps = {};

const SectionWrapper = styled.div`
  width: 100%;
  padding-top: 72px;
  padding-bottom: 77px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow:hidden;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SectionHeader = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 45px;
  line-height: 55px;
  color: #2d2d2d;
  font-weight: 500;
  margin: 0 auto;
  max-width: 600px;
  text-align: center;
  overflow: hidden;
  position: relative;
  padding-bottom: 28px;
  @media (max-width: 1280px) {
    font-size: 35px;
    max-width: 80%;
  }

  span {
    color: #008e8e;
    font-weight: 700;
    text-shadow: none;
  }
  ::after {
    width: 100%;
    max-width: 410px;
    margin: 0 auto;
    height: 10px;
    transform: translate(-50%, 0);
    background-image: url("/divider.svg");
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-position: center center;
    bottom: 0;
    left: 50%;
  }
`;
const SectionSubhead = styled.h5`
  color: #292929;
  font-family: Poppins, sans-serif;
  font-size: 17px;
  font-weight: 500;
  margin: 33px auto 0;
  max-width: 643px;
  @media (max-width: 768px) {
    max-width: 80%;
  }
`;

const TextComfort = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 643px;
  width: 100%;
  @media (max-width: 1440px) {
    max-width: 440px;
  }
  @media (max-width: 12800px) {
    max-width: 330px;
  }
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const ItemComfort = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 468px;
  overflow: hidden;
  position: relative;
  @media (max-width: 1280px) {
    max-width: 30%;
  }
  @media (max-width: 768px) {
    display: none;
  }
  .outline {
    margin: 64px 0 0 -50px;
    z-index: 100;
  }
`;

const TitleItem = styled.h5`
  color: #333333;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 19px;
  margin-top: 5px;

  ::after {
    width: 2px;
    height: 138px;
    transform: translate(-50%, -50%);
    background-color: #ededed;
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 100%;
  }
`;

const ImageItem = styled.img`
  margin-top: 37px;
  @media (max-width: 768px) {
    margin: 0 24px;
  }
  @media (max-width: 470px) {
    display: none;
  }
`;

const MobileImages = styled.div`
  display: none;
  margin: 44px 0 24px;
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
  }
  img {
    width: 40%;
    padding: 0 16px;
  }
`;

export default MoreComfort;
