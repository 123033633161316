import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
//import BannerHeader from "../components/BannerHeader/BannerHeader";
// import HeroSection from '../components/HeroSection/HeroSection';
import HeroSectionBuy from '../components/HeroSectionBuy/HeroSectionBuy';
// import HeroNew from '../components/HeroNew/HeroNew';
import PartnersSection from '../components/PartnersSection/PartnersSection';
import VideoExplanation from '../components/VideoExplanation/VideoExplanation';
import SajdahFeatures from '../components/SajdahFeatures/SajdahFeatures';
import SajdahReasons from '../components/SajdahReasons/SajdahReasons';
import HowSajdahWorks from '../components/HowSajdahWorks/HowSajdahWorks';
import MoreComfort from '../components/MoreComfort/MoreComfort';
import MobileApplication from '../components/MobileApplication/MobileApplication';
import SeeOn from '../components/SeeOn/SeeOn';
import ShariahCompliant from '../components/ShariahCompliant/ShariahCompliant';
import MeetTeam from '../components/MeetTeam/MeetTeam';
import TechSpecification from '../components/TechSpecification/TechSpecifiaction';
// import SajdahTimeline from '../components/SajdahTimeline/SajdahTimeline';
import SmartControl from '../components/SmartControl/SmartControl';
import SajdahTestimonial from '../components/SajdahTestimonial/SajdahTestimonial';
import ContactForm from '../components/ContactForm/ContactForm';
const SeoData = {
  Title: "Sajdah - The World's First Smart Educational Prayer Rug",
  Keywords: [`Sajdah`, `Prayer`, `Rug`, `Islam`, `Smart`],
  Description:
    "Sajdah, the world's first smart educational prayer rug that teaches new Muslims and children the second most important pillar in Islam.",
  Image: 'https://getsajdah.com/sajdah_og_image_thumb.jpg',
  url: 'https://getsajdah.com/',
  titleTemplate: "Sajdah - The World's First Smart Educational Prayer Rug"
};

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        description={SeoData.Description}
        keywords={SeoData.Keywords}
        title={SeoData.Title}
        image={SeoData.Image}
        url={SeoData.url}
        titleTemplate={SeoData.titleTemplate}
      />
      <HeroSectionBuy />
      {/* <HeroNew /> */}
      <VideoExplanation />
      <SajdahFeatures />
      <SajdahReasons />
      <SmartControl />
      <HowSajdahWorks />
      <MoreComfort />
      <MobileApplication />
      <SeeOn />
      <ShariahCompliant />
      <SajdahTestimonial />
      {/* <SajdahTimeline /> */}
      <MeetTeam />
      <TechSpecification />
      <PartnersSection />
      <ContactForm />
    </Layout>
  );
};

export default IndexPage;
