import React from "react";
import styled from "styled-components";
import AnimeImage from "../Animation/AnimeImage";
const ShariahData = {
  title: "Sajdah is <span>Shariah-Compliant</span>",
  text:
    "While designing Sajdah, we’ve made sure it follows all the recommended praying guidelines. Sajdah, its app, and all features comply with the Shariah law, and is recognized by every madhhab as a proper prayer education tool."
};

const ShariahCompliant = () => {
  return (
    <SectionWrapper>
      <SectionContainer>
        <ContentSide>
          <SectionHeader
            dangerouslySetInnerHTML={{ __html: ShariahData.title }}
          />
          <SectionSubhead>{ShariahData.text}</SectionSubhead>
          {/* <StyledCta>Read More</StyledCta> */}
        </ContentSide>
        <AnimeImage>
          <ImageSide>
            <img src="/prayer-shariah.png" alt="shariah compilant" />
          </ImageSide>
        </AnimeImage>
        
      </SectionContainer>
    </SectionWrapper>
  );
};

ShariahCompliant.propTypes = {};

ShariahCompliant.defaultProps = {};

const SectionWrapper = styled.div`
  width: 100%;
  padding: 75px 0 51px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(to left, #eaf9f6 0%, #ffffff 100%);
  position: relative;
  overflow: hidden;
  ::before {
    width: 100%;
    max-width: 1126px;
    margin: 0 auto;
    height: 100%;
    background-image: url("/bg-shariah.png");
    transform: rotate(-180deg);
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-position: center center;
    bottom: 0;
    left: 0;
    z-index: 1;
    opacity: 0.5;
  }
  ::after {
    width: 100%;
    max-width: 1126px;
    margin: 0 auto;
    height: 100%;
    background-image: url("/bg-shariah.png");
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-position: center center;
    bottom: 0;
    right: 0;
    z-index: 1;
    opacity: 0.5;
  }
`;

const SectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  @media (max-width: 1280px) {
    max-width:90%;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const ContentSide = styled.div`
  width: 100%;
  max-width: 690px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 10;
  @media (max-width: 768px) {
    align-items: center;
    margin: 0 auto;
  }
`;

const ImageSide = styled.div`
  width: 100%;
  max-width: 354px;
  position: relative;
  z-index: 10;
  
  @media (max-width: 768px) {
    margin: 32px auto;
  }
  @media (max-width: 470px) {
    display: none;
  }
`;

const SectionHeader = styled.div`
  font-family: Poppins, sans-serif;
  font-size: 45px;
  line-height: 55px;
  color: #2d2d2d;
  font-weight: 500;
  margin: 0 auto;
  max-width: 680px;
  text-align: center;
  overflow: hidden;
  position: relative;
  padding-bottom: 28px;
  @media (max-width: 768px) {
    font-size: 35px;
    margin: 0 24px;
  }

  span {
    color: #008e8e;
    font-weight: 700;
    text-shadow: none;
  }
  ::after {
    width: 100%;
    margin: 0 auto;
    height: 10px;
    transform: translate(-50%, 0);
    background-image: url("/divider.svg");
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-position: center center;
    bottom: 0;
    left: 50%;
  }
`;

const SectionSubhead = styled.h5`
  color: #292929;
  font-family: Poppins, sans-serif;
  font-size: 17px;
  font-weight: 500;
  margin: 33px 0 0;
  text-align: left;
  @media (max-width: 768px) {
    margin: 33px 24px 0;
  }
`;

// const StyledCta = styled.button`
//   width: 100%;
//   max-width: 266px;
//   text-align: center;
//   background-color: #008e8e;
//   border-radius: 4px;
//   outline: none;
//   border: none;
//   padding: 22px 0;
//   color: white;
//   cursor: pointer;
//   margin-top: 40px;
//   font-family: Poppins, sans-serif;
//   :hover {
//     opacity: 0.9;
//   }
// `;

export default ShariahCompliant;
